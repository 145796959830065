import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ClientService } from '../services/client/client.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedUrl = environment.BASE_URL_PRECOG;
    if (req.url.includes(excludedUrl)) {
      return next.handle(req);
    }
    
    const clientId = sessionStorage.getItem('client_id');

    let modifiedReq = req;

    if (clientId) {
      modifiedReq = req.clone({
        setParams: {
          'client_id': clientId,
        },
      });
    }

    return next.handle(modifiedReq);
  }
}
