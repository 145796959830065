<div *ngIf="isTenantsLoaded">
  <header>
    <app-header
      [tenants]="tenants"
      [selectedTenant]="selectedTenant"
      (tenantChange)="onTenantChange($event)"
    ></app-header>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <!-- @if (dataService.isLoading) {
    <div class="loader-conatiner">
      <div class="loader">
        <object type="image/svg+xml" data="../../../assets/loader/loader.svg"></object>
      </div>
    </div>
  } -->
</div>