import { environment } from './environments/environment';

import('./bootstrap').catch((err) => console.error(err));

//hide console log for dev and prod
if (!environment.local) {
  console.log = () => {};  // Disable console.log
  console.warn = () => {}; // Optional: disable warnings
  console.error = () => {}; // Optional: disable errors
}
