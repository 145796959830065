// auth.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private keycloakService: KeycloakService,
    private toastr: ToastrService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Log the user out if a 401 Unauthorized error is encountered
          this.keycloakService.logout();
        } else {
          // Show a generic "Something went wrong" toast for other errors
          this.toastr.error('Something went wrong', 'Error', {
            timeOut: 3000,
            closeButton: true,
            progressBar: true
          });
        }

        // Pass the error to the caller
        return throwError(() => error);
      })
    );
  }
}
