import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DataService } from './shared/services/data/data.service';
import { HeaderComponent } from './layout/header/header.component';
import { ApiService } from './shared/services/api/api.service';
import { ToastrModule } from 'ngx-toastr';
import { KeycloakService } from 'keycloak-angular';
import { ClientService } from './shared/services/client/client.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    HttpClientModule,
    HeaderComponent,
    ToastrModule,
  ],
  providers: [DataService, ApiService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public loadData: boolean = false;
  tenants: any[] = [];
  selectedTenant: any;
  isTenantsLoaded = false; // Initialize as false

  constructor(
    public dataService: DataService, 
    private keycloakService: KeycloakService, 
    private apiService: ApiService,
    private clientService: ClientService,
  ){}

  ngOnInit(): void {
    this.getAllTenants();
  }

  setClientId(clients: string[]) {
    const currentClientId = sessionStorage.getItem('client_id') || this.clientService.getClientId();
    this.selectedTenant = clients.find((client: string) => client === currentClientId) || clients[0];

    if (this.selectedTenant) {
      if (currentClientId !== this.selectedTenant) {
        // Update tenant ID in TenantService and session storage
        this.clientService.setClientId(this.selectedTenant);
        sessionStorage.setItem('client_id', this.selectedTenant);
      }
    }
    this.isTenantsLoaded = true;
  }

  public getAllTenants() {
    const instance = this.keycloakService.getKeycloakInstance().tokenParsed;
    if (instance.resource_access?.['realm-management']?.roles.includes('realm-admin')) {
      this.apiService.getTenants().subscribe({
        next: (data: any) => {

          this.tenants = data.filter(item => item.attributes.client_id?.[0] !== undefined)
                              .map(item => item.attributes.client_id[0]);
          this.setClientId(this.tenants);
        },
        error: (err) => {
          console.error('Failed to fetch tenants', err);
          this.isTenantsLoaded = true;
        },
      });
    } else {
      this.tenants = instance['client_id'];
      this.setClientId(this.tenants);
    }
  }

  onTenantChange(selectedTenant: any): void {
    this.clientService.setClientId(selectedTenant);
    sessionStorage.setItem('client_id', selectedTenant);
  
    location.reload();
  }

  async clearItem() {
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    if (isLoggedIn) {
      // Remove specific local storage items after login
      localStorage.clear();
    }
  }
}
