export const environment = {
  BUILD_ENV: 'local',
  production: false,
  development: false,
  local: true,
  BASE_URL: 'https://api-stream-pf35w2ahoq-uc.a.run.app/',
  BASE_URL_PRODUCT: 'https://api-product-dev.predigle.com/',
  BASE_URL_ORDER: 'https://api-entity-order-pf35w2ahoq-uc.a.run.app/',
  LUNA_CLIENT_ID: 'luna',

  precog: {
    BASE_URL: 'https://api-precog-dev.predigle.com/',
    WAVE_BOT_ID: "6784d62f6f0387f25b07221b",
    ORDER_BOT_ID: "6784d6566f0387f25b07221c",
    CONTAINER_BOT_ID: "67765eb301ad8abc6f1f5130"
  },

  keycloak: {
    authority: 'https://accounts.predigle.com',
    get redirectUri() {
      return window.location.href;
    },
    get postLogoutRedirectUri() {
      return window.location.href;
    },
    realm: 'predigle-dev',
    clientId: 'stream-frontend',
    groupId: 'd1dd0c7a-f957-47c3-b061-12c7b48af315',
    onLoad: 'login-required'
  },
  idleConfig: { idle: 10, timeout: 60, ping: 10 },
  USER_ENTITY_URL: "https://api-entity-user-pf35w2ahoq-uc.a.run.app/account/user",
  CLIENT_ENTITY_URL: "https://api-entity-client-pf35w2ahoq-uc.a.run.app/client",
};