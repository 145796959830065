<div>
  <header>
    <app-header></app-header>
  </header>
  <main>
    <router-outlet></router-outlet>
  </main>
  <!-- @if (dataService.isLoading) {
    <div class="loader-conatiner">
      <div class="loader">
        <object type="image/svg+xml" data="../../../assets/loader/loader.svg"></object>
      </div>
    </div>
  } -->
</div>